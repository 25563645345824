import { SET_AUTH, SET_USER, SET_ROLE,LOGOUT } from "./../actions/actionTypes";

const DEFAULT_STATE = {
  keycloak: {},
  authenticated: false,
  user: {},
  corporateEmployee: {}
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_AUTH:
      return {
        ...state,
        authenticated: action.auth.authenticated,
        keycloak: action.auth.keycloak
      };
    case SET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          employeeID: action.user.employeeID,
          ssn: action.user.ssn,
          firstName: action.user.given_name,
          lastName: action.user.family_name,
          email: action.user.email
        }
      };
    case SET_ROLE:
      return {
        ...state,
        corporateEmployee: action.role
      };
    case LOGOUT:
      return {
        // ...state,
        // keycloak: {},
        // authenticated: false,
        // user: {},
        // corporateEmployee: {}
      };
    default:
      return state;
  }
};
