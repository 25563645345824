import React from 'react';
import { ScaleLoader } from 'react-spinners';
import './Loading.css'
import { isMobile } from 'react-device-detect';
import CircularProgress from '@material-ui/core/CircularProgress';


class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }
  render() {
    if(isMobile){
      return ( <div className='sweet-loading'>
      <CircularProgress disableShrink style={{textAlign:'center'}} />
      <p>Please wait.....</p>
      </div>)
    }
    return (
      <div className='sweet-loading'>
        <ScaleLoader
          sizeUnit={"px"}
          size={4}
          color={'#4f9cda'}
          height={35}
          loading={this.state.loading}
        />
        <p>Please wait . . .</p>
      </div> 
    )
  }
}

export default Loading;