import {
    GET_IMAGE_START,
    GET_IMAGE,
    GET_IMAGE_FAIL
} from "./../actions/actionTypes";

const DEFAULT_STATE = {
    isDataLoaded: false,
    isLoading: false,
    timecardImage: {},
    error: {}
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case GET_IMAGE_START:
            return { ...state, isLoading: true };
        case GET_IMAGE:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                timecardImage: action.timecardImage
            };
        case GET_IMAGE_FAIL:
            return {
                ...state,
                isDataPosting: false,
                isDataPosted: false,
                error: action.error
            }
        default:
            return state;
    }
};
