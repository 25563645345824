import {
    POST_IMAGE_START,
    POST_IMAGE,
    POST_IMAGE_FAIL
  } from "./../actions/actionTypes";
  
  const DEFAULT_STATE = {
    isDataPosted: false,
    isDataPosting: false,
    postedImageObject : {},
    error: {}
  };
  
export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case POST_IMAGE_START:
            return { ...state, isDataPosting: true };
        case POST_IMAGE:
            return {
                ...state,
                isDataPosting: false,
                isDataPosted: true,
                postedImageObject: action.postedImageObject
            };
        case POST_IMAGE_FAIL:
            return {
                ...state,
                isDataPosting: false,
                isDataPosted: false,
                error: action.error
            }
        default:
            return state;
    }
};
  