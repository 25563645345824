import {
    GET_PAST_ASSIGNMENTS_START,
    GET_PAST_ASSIGNMENTS,
    GET_PAST_ASSIGNMENTS_FAIL,
  } from "./../actions/actionTypes";
  
  const DEFAULT_STATE = {
    isDataLoaded: false,
    isLoading: false,
    pastAssignments: [],
    error: {}
  };
  
  export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case GET_PAST_ASSIGNMENTS_START:
        return { ...state, isLoading: true };
      case GET_PAST_ASSIGNMENTS:
        return {
          ...state,
          isLoading: false,
          isDataLoaded: true,
          pastAssignments: action.pastAssignments
        };
        case GET_PAST_ASSIGNMENTS_FAIL:
        return {
          ...state,
          isLoading: false,
          isDataLoaded: false,
          error: action.error
        };
      default:
        return state;
    }
  };
  