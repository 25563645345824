import {
  POST_SAVE_OBJECT_START,
  POST_SAVE_OBJECT,
  POST_SAVE_OBJECT_FAIL
} from "./../actions/actionTypes";

const DEFAULT_STATE = {
  isDataPosted: false,
  isDataPosting: false,
  isError:false,
  postedObject : {},
  error: {}
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case POST_SAVE_OBJECT_START:
      return { ...state, isDataPosting: true };
    case POST_SAVE_OBJECT:
      return {
        ...state,
        isDataPosting: false,
        isDataPosted: true,
        postedObject: action.postedObject
      };
    case POST_SAVE_OBJECT_FAIL:
      return {...state,
        isDataPosting: false,
        isDataPosted: false,
        isError:true,
        error: action.error
      }
    default:
      return state;
  }
};
