export const isEmptyArray = (array=[]) =>array.length<=0?true:false;

export const isEmptyObject = (obj={})=>(typeof obj==='undefined'||JSON.stringify(obj)==='{}'?true:false)

export const isNotEmptyArray = (arr=[]) =>arr.length>0?true:false;

export const isNotEmptyObject = (obj={})=>{
    return(typeof obj==='object'&&typeof obj!=='undefined'&& JSON.stringify(obj)!=='{}'?true:false)
}

export const isObjectButEmpty = (obj={})=>(typeof obj==='object'&& JSON.stringify(obj)==='{}'?true:false)

export const formatFileSize = (bytes,decimalPoint) => {
    if(bytes === 0) return '0 Bytes';
    let k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
 }

export  const toFixed = (num, fixed)=> {
  const re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  if(typeof num!=='undefined'&&!isNaN(num))return num.toString().match(re)[0];
  return '00.00';
}

export const timeConvert = (n) => {
    let num = n;
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + "." + rminutes ;
}

export const calulateTimedifference = (startTime, endTime) =>{
    const sTime = startTime.split(":");
    const startTimeHour = parseInt(sTime[0]);
    const startTimeminutes = parseInt(sTime[1]);
    const eTime = endTime.split(":");
    const endTimeHour = parseInt(eTime[0]);
    const endTimeminutes = parseInt(eTime[1]);
    const totalStartDecimalHours = startTimeHour+ startTimeminutes*(1/60)
    const totalEndDecimalHours = endTimeHour+ endTimeminutes*(1/60)
    const toltalDifference = parseFloat(toFixed(totalEndDecimalHours,5))-parseFloat(toFixed(totalStartDecimalHours,5))
    return (toFixed(toltalDifference,2));
}
