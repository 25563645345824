import {
    POST_IMAGE_START, POST_IMAGE, POST_IMAGE_FAIL,
     LOADING, POST_SAVE_OBJECT_FAIL, SIGNATURE_SUBMIT_LOADING, SUBMIT_LOADING
  } from "./actionTypes";
  import {instance} from '../utilities/services';
  import moment from "moment";
  import axios from 'axios';
  import { isMobile } from 'react-device-detect';


export const postImageObj = (imgObj) => async(dispatch) =>{
        try {
            dispatch({type: POST_IMAGE_START})
            const response = await instance.post('Timecard/updateEmpImage',imgObj);
            dispatch({type: POST_IMAGE, postedImageObject:response })
        } catch (error) {
            dispatch({type: POST_IMAGE_FAIL, error })
        }
}


export const postSaveObj = (otherData, timeCardData,imageObject,  buttonName, callback) => async(dispatch) =>{
    try {
        
        console.log(timeCardData)
        if(buttonName==='submit')dispatch({type:SUBMIT_LOADING,submit_loading:true})
       let saveSubmitTimeCardDetailDTO = []
       timeCardData.forEach((timecard,i)=>{
          if(typeof timecard.startTime!=='undefined'&& timecard.startTime !==null){
            if(typeof timecard.startTime!=='string')timecard.startTime = moment(timecard.startTime).format('HH:mm')
           }
           if(typeof timecard.endTime!=='undefined'&& timecard.endTime !==null){
             if(typeof timecard.endTime!=='string')timecard.endTime = moment(timecard.endTime).format('HH:mm')
           }
           
           saveSubmitTimeCardDetailDTO.push(timecard)
        })
        if(imageObject)instance.post('Timecard/uploadImageAndSignature',imageObject)
        isMobile?await instance.post('Timecard/employeeSaveSubmitTimecardForMobile',{...otherData,saveSubmitTimeCardDetailDTO}):await instance.post('Timecard/employeeSaveSubmitTimecard',{...otherData,saveSubmitTimeCardDetailDTO});
        dispatch({type:SUBMIT_LOADING,submit_loading:false})
        dispatch({type:LOADING,loading:false})
        callback(true)
        
    } catch (error) {
        dispatch({type: POST_SAVE_OBJECT_FAIL, error })
        dispatch({type:LOADING,loading:false})
        callback(false)
    }
}

export const updateQueue = (timeCardId) => async(dispatch)=>{
    return await instance.get(`Timecard/updateTtimecardEventQueue/${timeCardId}`)
}

export const supervisorRejection = (rejectionData) => async(dispatch)=>{
    return await instance.post('Timecard/timecardRejectionBySupervisor',rejectionData)
}

export const supervisorApproval = (approvalSignData, approvalData) => (dispatch)=>{
    dispatch({type:SIGNATURE_SUBMIT_LOADING,signature_submit_loading:true})
    return  axios.all([instance.post('Timecard/uploadImageAndSignature',approvalSignData),instance.post('Timecard/timecardApprovalBySupervisor',approvalData)])
    .then( () =>{
        dispatch({type:SIGNATURE_SUBMIT_LOADING,signature_submit_loading:false})
    }
    );
    
    }



