import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader, Navbar, Nav, NavbarBrand, NavItem, Container, Row, Col } from "reactstrap";
import { NavLink } from "react-router-dom";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import "./Toolbar.css";
import "./Toolbar-responsive.css";
import logo from "./TI-CorporateTM-Full.png";
import { connect } from "react-redux";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Calendar from 'react-calendar';
import moment from 'moment';
import * as actions from "./../../../../actions";
import {isNotEmptyObject} from './../../generalFunction/CommonFunction'

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      savemodal: false,
      helpModal: false,
      date: new Date(),
      alertDate: moment(new Date()).format('DD-MM-YYYY')
    };
    this.handleSelectDate = this.handleSelectDate.bind(this);
    this.toggle = this.toggle.bind(this);
    this.helpToggle = this.helpToggle.bind(this);
    this.handleClick=this.handleClick.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  helpToggle() {
      console.log(navigator.userAgent);
      this.setState({ helpModal: !this.state.helpModal });
  }

  activeStatus = {
    backgroundColor: "#BE1E2D",
    borderRadius: 5,
    padding: "12px 20px",
    color: "white",
    boxShadow: "0px 3px 10px 0px rgba(250,165,25,0.68)"
  }

  handleClick = () => {
    this.props.keycloak.logout();
  }

  handleSelectDate = (e) => {
    e.preventDefault()
    this.setState({
      modal: !this.state.modal
    })

    this.props.fetchAssignmentsForDay(this.props.employeeID, this.state.alertDate);
    this.props.history.push("/calendar");

  }

  onChange = (date) => {
    this.setState({ date, alertDate: moment(date).format('DD-MM-YYYY') });
  }


  render() {
    let exclaimationClasses = '';
    if(isNotEmptyObject(this.props.alerts.alertData)){
      if(isNotEmptyObject(this.props.alerts.alertData.CorrectionAssignments)){
        if(this.props.alerts.alertData.CorrectionAssignments.length > 0) {
          exclaimationClasses = 'fas fa-exclamation-circle number';
        }
      }
    }

    return (
        <Container>
          <Navbar className="toolbar">
            <NavbarBrand to="/">
              <img src={logo} alt="logo" id="nav-logo" />            
            </NavbarBrand>
            <Nav className="ml_auto">
  
              <div className="toolbar__toggle-button">
                <NavItem className="home-icon" id="home">
                  <NavLink exact to="/" className="nav-link" id="home">
                    <span className="profile" id="home"><i className="fas fa-home" id="home" /></span>
                  </NavLink>
                </NavItem>
                <DrawerToggleButton click={this.props.drawerClickHandler} />
              </div>
  
              <NavItem className="toolbar_navigation-items user" id="home">
                <NavLink activeStyle={this.activeStatus} exact to="/" className="nav-link"  id="home">
                  <span className="sr-only" id="home">(current)</span>
                  <i className="fas fa-home" id="home" />
                </NavLink>
              </NavItem>
  
              <NavItem className="toolbar_navigation-items user">
                <NavLink to="/open" activeStyle={this.activeStatus} className="nav-link">Open</NavLink>
              </NavItem>

              <NavItem className="toolbar_navigation-items user">
                <NavLink activeStyle={this.activeStatus}  to="/correction" className="notif nav-link"><i className={exclaimationClasses}></i>Correction</NavLink>
              </NavItem>
  
              <NavItem className="toolbar_navigation-items user">
                <NavLink activeStyle={this.activeStatus}  to="/approved" className="notif nav-link">Approved</NavLink>
              </NavItem>
  
              <NavItem className="toolbar_navigation-items user">
                <NavLink activeStyle={this.activeStatus} to="/submitted" className="nav-link" >Submitted</NavLink>
              </NavItem>
  
              <div onClick={this.toggle} className="toolbar_navigation-items user" id="calendar">
                <div  className="nav-link" id="calendar" >
                  <i className="fas fa-calendar" id="calendar" />
                </div>
              </div>
              <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} id="calendar_modal">
  
                <div className="cal_wrapper">
                  <div className="ant-alert ant-alert-info ant-alert-no-icon">
                    <span className="ant-alert-message">{`You selected date: ${this.state.alertDate}`}</span><span className="ant-alert-description"></span>
                  </div>
                  <Calendar onChange={this.onChange} value={this.state.date} />
                  <Row className="justify-content-center">
                    <Col md="3" sm="5" xs="5">
                      <button onClick={this.handleSelectDate} className="fetch_btn">Fetch Assignments</button>
                    </Col>
                  </Row>
                </div>
              </Modal>
  
              <li className="bar1" />
              <NavItem className="toolbar_navigation-items user" >
                <NavLink to="/help" activeStyle={this.activeStatus} onClick={this.helpToggle} className="nav-link">
                  Help
                </NavLink>
              </NavItem>

                <Modal isOpen={this.state.helpModal} toggle={this.helpToggle} className={this.props.className} id={"calendar_modal"}>
                    <ModalHeader toggle={this.helpToggle}>Help</ModalHeader>
                    <ModalBody>
                        <iframe title="how to use" width="560" height="315" src="https://www.youtube.com/embed/QHXESFubBaQ" frameBorder="0"
                                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                        <div style={{marginTop: '1em'}}>
                            <a href={'./TimeCapsureInstructions.pdf'} target={'_blank'}>Open PDF Instructions</a>
                        </div>
                    </ModalBody>
                </Modal>

                <NavItem className="toolbar_navigation-items user" >
                 <a href="https://employees.tradesmeninternational.com"  target="_blank" className="nav-link">
                 Employee Connect
                </a>
              </NavItem>

              <div className="toolbar_navigation-items user" onClick={this.handleClick}>
                <div className="nav-link">
                  <i className="fas fa-power-off" />
                </div>
              </div>
  
            </Nav>
          </Navbar>
    </Container>
    )
  }

}

function mapStateToProps(state) {
  return {
    employeeID: state.authInfo.user.employeeID,
    keycloak: state.authInfo.keycloak,
    alerts: state.alerts
  };
}
export default connect(mapStateToProps, actions)(Toolbar);

