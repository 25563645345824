import {
    GET_ADMIN_COMMENT, GET_ADMIN_COMMENT_START, GET_ADMIN_COMMENT_FAIL,
    GET_ALERT_DATA_START, GET_ALERT_DATA_FAIL, GET_ALERT_DATA,
    GET_ASSIGNMENTS_FOR_DAY_START, GET_ASSIGNMENTS_FOR_DAY,  GET_ASSIGNMENTS_FOR_DAY_FAIL,
    GET_CW_ASSIGNMENTS_START, GET_CW_ASSIGNMENTS, GET_IMAGE_START, GET_IMAGE, GET_IMAGE_FAIL,
    GET_IPADDRESS_START, GET_IPADDRESS, GET_IPADDRESS_FAIL, 
    GET_PAST_ASSIGNMENTS_START, GET_PAST_ASSIGNMENTS, GET_PAST_ASSIGNMENTS_FAIL,
   
    GET_TIMECARD_START, GET_TIMECARD,  GET_TIMECARD_FAIL,LOADING

  } from "./actionTypes";
import {instance} from '../utilities/services';


export const fetchAdminComment = (id) => async(dispatch) =>{
        try {
            dispatch({type: GET_ADMIN_COMMENT_START})
            const response = await instance.get(`Timecard/comments/${id}`);
            dispatch({type: GET_ADMIN_COMMENT, comment:response })
        } catch (error) {
            dispatch({type: GET_ADMIN_COMMENT_FAIL, error })
        }
}

export const fetchAlert = (id) => async(dispatch) =>{
    try {
        dispatch({type: GET_ALERT_DATA_START})
        const response = await instance.get(`Assignment/assignmentsByStatus/${id}`);
        dispatch({type: GET_ALERT_DATA, alertData:response })
    } catch (error) {
        dispatch({type: GET_ALERT_DATA_FAIL, error })
    }
}

export const fetchAssignmentsForDay = (employeeId,date) => async(dispatch) =>{
    try {
        dispatch({type: GET_ASSIGNMENTS_FOR_DAY_START})
        const response = await instance.get(`Assignment/assignmentsByDate/${employeeId}/${date}`);
        dispatch({type: GET_ASSIGNMENTS_FOR_DAY, assignments:response })
    } catch (error) {
        dispatch({type: GET_ASSIGNMENTS_FOR_DAY_FAIL, error })
    }
}

export const fetchCurrentAssignments = (id,page=1) => async(dispatch) =>{
    try {
        dispatch({type: GET_CW_ASSIGNMENTS_START})
        const response = await instance.get(`Assignment/currentWeekAssignments/${id}`);
        dispatch({type: GET_CW_ASSIGNMENTS, currentAssignments:response })
    } catch (error) {
        console.log(error);
        dispatch({type: GET_ASSIGNMENTS_FOR_DAY_FAIL, error })
    }
}

export const fetchTimecardImage = (timecardId) => async(dispatch) =>{
    try {
        dispatch({type: GET_IMAGE_START})
        const response = await instance.get(`Timecard/employeeDoc/${timecardId}`);
        dispatch({type: GET_IMAGE, timecardImage:response })
    } catch (error) {
        dispatch({type: GET_IMAGE_FAIL, error })
    }
}

export const fetchIPAddress = () => async(dispatch) =>{
    try {
        dispatch({type: GET_IPADDRESS_START})
        const response = await instance.get('Timecard/getMyIpAddress');
        dispatch({type: GET_IPADDRESS, IPAddress:response })
    } catch (error) {
        dispatch({type: GET_IPADDRESS_FAIL, error })
    }
}


export const fetchPastAssignments = (id,page) => async(dispatch) =>{
    try {
        dispatch({type: GET_PAST_ASSIGNMENTS_START});
        const response = await instance.get(`Assignment/previousWeeksAssignments/${id}/${page}/200`);
        dispatch({type: GET_PAST_ASSIGNMENTS, pastAssignments:response.data.PreviousWeeksAssignments })
    } catch (error) {
        dispatch({type: GET_PAST_ASSIGNMENTS_FAIL, error })
    }
}



export const fetchTimecard = (timecardId) => async(dispatch) =>{
    try {
        dispatch({type: GET_TIMECARD_START});
        dispatch({type:LOADING,loading:true})
        const response = await instance.get(`Timecard/employeeTimeCardSaveSubmitView/${timecardId}`);
        dispatch({type: GET_TIMECARD, timecard:response.data })
        dispatch({type:LOADING,loading:false})
    } catch (error) {
        dispatch({type: GET_TIMECARD_FAIL, error })
        dispatch({type:LOADING,loading:false})
    }
}

export const fetchTimecardView = (timecardId) => async(dispatch) =>{
    try {
        dispatch({type: GET_TIMECARD_START});
        dispatch({type:LOADING,loading:true})
        const response = await instance.get(`Timecard/timeCardSaveSubmitView/${timecardId}`);
        dispatch({type: GET_TIMECARD, timecard:response.data })
        dispatch({type:LOADING,loading:false})
    } catch (error) {
        dispatch({type: GET_TIMECARD_FAIL, error })
        dispatch({type:LOADING,loading:false})
    }
}






