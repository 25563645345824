import React, {Component} from 'react';
import routes from './common/routes/routes';
import Routing from './../utilities/routing';
import PrivateRoutes from './common/routes/PrivateRoutes';
import NavbarComponent from './common/navbar/NavbarComponent';
import NotFoundComponent from './common/NotFoundComponent';
import { connect } from "react-redux";
import * as actions from './../actions'
// import Loading from './common/loaders/Loading';
import '../App.css';


const withRouter = Routing.withRouter;
const Switch = Routing.Switch;
const Route = Routing.Route;

class MainComponent extends Component {
    constructor(props){
        super(props)
        this.state = {
            pageCount : 2
        }
      }
    
    async  componentDidMount() {
        localStorage.setItem('localVar','false');
       await this.props.authUser();
      }
    
      // alerts = ()=>{
      //   if( Object.keys(this.props.alerts).length){
      //    return <Alert />
      //   }else{
      //     return null
      //   }
      // }

      render() {
        const {keycloak, authenticated} = this.props;
        if(!keycloak&&!authenticated){
          return null
          // <Loading/>
        }
        return (
            <div className="container-fluid padding-top-xs-15">
              <NavbarComponent {...this.props} />
                  <Switch>
                      {routes.map((route, i) => {
                          return (
                              <PrivateRoutes key={i} {...route} {...this.props}/>
                          )
                      })}
                      <Route component={NotFoundComponent}/>
                  </Switch>
              </div>
        );
      }
}

const mapStateToProps =(state)=>{
    return{
       keycloak: state.authInfo.keycloak,
       authenticated: state.authInfo.authenticated,
       userRole:state.authInfo.corporateEmployee,
       alerts : state.alerts,
       employeeID : state.authInfo.user.employeeID,
    }
  }

export default withRouter(connect(mapStateToProps, actions)(MainComponent));