import React, { Component } from 'react';
import {Provider} from 'react-redux';
import store from './utilities/storage/store';
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import MainComponent from './components/MainComponent';
import { Router } from './utilities/routing';

const getUserConfirmation = (dialogKey, callback) => {
  // use "message" as Symbol-based key
  const dialogTrigger = window[Symbol.for(dialogKey)];

  if (dialogTrigger) {
    // pass the callback to delegate
    // to the invoked dialog
    return dialogTrigger(callback);
  }

  // Fallback to allowing navigation
  callback(true);
};


class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router basename={'/field'} getUserConfirmation={getUserConfirmation}>
         <MainComponent/>
        </Router>
      </Provider>
    );
  }
}


export default App;
