import {
    GET_ASSIGNMENTS_FOR_DAY_START,
    GET_ASSIGNMENTS_FOR_DAY,
    GET_ASSIGNMENTS_FOR_DAY_FAIL,
    } from "./../actions/actionTypes";
  
  const DEFAULT_STATE = {
    isDataLoaded: false,
    isLoading: false,
    assignments: []
  };
  
  export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case GET_ASSIGNMENTS_FOR_DAY_START:
        return { ...state, isLoading: true };
      case GET_ASSIGNMENTS_FOR_DAY:
        return {
          ...state,
          isLoading: false,
          isDataLoaded: true,
          assignments: action.assignments
        };
        case GET_ASSIGNMENTS_FOR_DAY_FAIL:
        return {
          ...state,
          isLoading: false,
          isDataLoaded: false,
          error: action.error
        };
      default:
        return state;
    }
  };