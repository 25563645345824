import axios from "axios";
import {API_SERVER_URL, API_SERVER_PORT, API_SERVER_TTSSERVICE} from './../../actions/actionTypes';

const baseURL= `${API_SERVER_URL}:${API_SERVER_PORT}/${API_SERVER_TTSSERVICE}/`;

export const instance = axios.create({
    baseURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",  'Cache-Control': 'no-cache' 
    }
});