export const ADD_ERROR = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";

export const GET_CW_ASSIGNMENTS = "GET_CW_ASSIGNMENTS";
export const GET_CW_ASSIGNMENTS_START = "GET_CW_ASSIGNMENTS_START";
export const GET_CW_ASSIGNMENTS_FAIL = "GET_CW_ASSIGNMENTS_FAIL";


export const GET_SUBMITTED_ASSIGNMENTS = "GET_SUBMITTED_ASSIGNMENTS";
export const GET_SUBMITTED_ASSIGNMENTS_START = "GET_SUBMITTED_ASSIGNMENTS_START";
export const GET_SUBMITTED_ASSIGNMENTS_FAIL = "GET_SUBMITTED_ASSIGNMENTS_FAIL";

export const GET_ALERT_DATA = "GET_ALERT_DATA";
export const GET_ALERT_DATA_START = "GET_ALERT_DATA_START";
export const GET_ALERT_DATA_FAIL = "GET_ALERT_DATA_FAIL";

export const SET_USER = "SET_USER";
export const SET_USER_START="SET_USER_START";
export const SET_ROLE = "SET_ROLE";
export const SET_AUTH = "SET_AUTH";
export const LOGOUT = "LOGOUT";

export const GET_TIMECARD = "GET_TIMECARD";
export const GET_TIMECARD_START = "GET_TIMECARD_START";
export const GET_TIMECARD_FAIL = "GET_TIMECARD_FAIL";

export const POST_SAVE_OBJECT = "POST_SAVE_OBJECT";
export const POST_SAVE_OBJECT_START = "POST_SAVE_OBJECT_START";
export const POST_SAVE_OBJECT_FAIL = "POST_SAVE_OBJECT_FAIL";


export const ON_SCROLL_LOAD = "ON_SCROLL_LOAD";
export const ON_SCROLL_LOAD_START = "ON_SCROLL_LOAD_START";

export const POST_IMAGE_START = "POST_IMAGE_START";
export const POST_IMAGE = "POST_IMAGE";
export const POST_IMAGE_FAIL = "POST_IMAGE_FAIL";

export const GET_IMAGE_START = "GET_IMAGE_START";
export const GET_IMAGE = "GET_IMAGE";
export const GET_IMAGE_FAIL = "GET_IMAGE_FAIL";

export const GET_PAST_ASSIGNMENTS_START = "GET_PAST_ASSIGNMENTS_START";
export const GET_PAST_ASSIGNMENTS = "GET_PAST_ASSIGNMENTS";
export const GET_PAST_ASSIGNMENTS_FAIL = "GET_PAST_ASSIGNMENTS_FAIL";

export const GET_ADMIN_COMMENT_START = "GET_ADMIN_COMMENT_START";
export const GET_ADMIN_COMMENT = "GET_ADMIN_COMMENT";
export const GET_ADMIN_COMMENT_FAIL = "GET_ADMIN_COMMENT_FAIL";

export const GET_ASSIGNMENTS_FOR_DAY_START = "GET_ASSIGNMENTS_FOR_DAY_START";
export const GET_ASSIGNMENTS_FOR_DAY = "GET_ASSIGNMENTS_FOR_DAY";
export const GET_ASSIGNMENTS_FOR_DAY_FAIL = "GET_ASSIGNMENTS_FOR_DAY_FAIL";

export const GET_IPADDRESS_START = "GET_IPADDRESS_START";
export const GET_IPADDRESS = "GET_IPADDRESS";
export const GET_IPADDRESS_FAIL = "GET_IPADDRESS_FAIL";

export const LOADING="LOADING";
export const SUBMIT_LOADING="SUBMIT_LOADING";
export const SIGNATURE_SUBMIT_LOADING="SIGNATURE_SUBMIT_LOADING";

export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;
export const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
export const API_SERVER_TTSSERVICE = process.env.REACT_APP_API_SERVER_TTSSERVICE;





