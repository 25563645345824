import Keycloak from "keycloak-js";
import { SET_AUTH, SET_USER } from "./actionTypes";
import { fetchIPAddress,fetchCurrentAssignments,fetchPastAssignments,fetchAlert} from  "./../actions";

export const setAuth = (auth) =>
 ({
    type: SET_AUTH,
     auth
  });


export const authUser = () =>async(dispatch)=>{
   const keycloak = Keycloak("/keycloak.json");
  try{
    return keycloak.init({ onLoad: "login-required" }).success(authenticated => {
      dispatch(setAuth({ keycloak: keycloak, authenticated: authenticated }));
      return  keycloak.loadUserInfo().success(userInfo => {
              dispatch(({
                type: SET_USER,
                user:userInfo
            }));
               localStorage.setItem('empLocalId',userInfo.employeeID)
               dispatch(fetchCurrentAssignments(userInfo.employeeID))
               dispatch(fetchPastAssignments(userInfo.employeeID,1))
               dispatch(fetchAlert(userInfo.employeeID))
               dispatch(fetchIPAddress()) 
            })
          })
  }catch(e){
    console.log(e)
  }
  
  
}
