import {
  GET_CW_ASSIGNMENTS_START,
  GET_CW_ASSIGNMENTS,GET_CW_ASSIGNMENTS_FAIL

} from "./../actions/actionTypes";

const DEFAULT_STATE = {
  isDataLoaded: false,
  isLoading: false,
  currentAssignments: {},
  error: {}
};

export default (state = DEFAULT_STATE, action) => {
   switch (action.type) {
    case GET_CW_ASSIGNMENTS_START:
      return { ...state, isLoading: true };
    case GET_CW_ASSIGNMENTS:
      return {
        ...state,
        isLoading: false,
        isDataLoaded: true,
        currentAssignments: action.currentAssignments.data
      };
      case GET_CW_ASSIGNMENTS_FAIL:
      return {
          ...state,
          isLoading: false,
          isDataLoaded: false,
          error: action.error
      }
    default:
      return state;
  }
};
