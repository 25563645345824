import { SET_USER, SET_ROLE, LOGOUT } from "./actionTypes";


export const setUser =(user)=>({
    type: SET_USER,
    user
})

export const setRole = (role) =>({
     type: SET_ROLE,
    role
})

export const logout=()=>({type: LOGOUT});
