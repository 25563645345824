import {
    GET_IPADDRESS_START,
    GET_IPADDRESS,
    GET_IPADDRESS_FAIL
  } from "./../actions/actionTypes";



const DEFAULT_STATE = {
    isDataLoaded: false,
    isLoading: false,
    IPAddress: "",
    error: {}
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case GET_IPADDRESS_START:
            return { ...state, isLoading: true };
        case GET_IPADDRESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                IPAddress: action.IPAddress
            };
        case GET_IPADDRESS_FAIL:
            return {
                ...state,
                isDataPosting: false,
                isDataPosted: false,
                error: action.error
            }
        default:
            return state;
    }
};
