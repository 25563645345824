import {
    GET_TIMECARD_START,
    GET_TIMECARD,
    GET_TIMECARD_FAIL
  } from "./../actions/actionTypes";

  const DEFAULT_STATE = {
    isDataLoaded: false,
    isLoading: false,
    timecard: {},
    error: {}
  };
  
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GET_TIMECARD_START:
      return { ...state, isLoading: true };
    case GET_TIMECARD:
      return {
        ...state,
        isLoading: false,
        isDataLoaded: true,
        timecard: action.timecard
      };
    case GET_TIMECARD_FAIL:
      return {
        ...state,
        isDataPosting: false,
        isDataPosted: false,
        error: action.error
      }
    default:
      return state;
  }
};
  