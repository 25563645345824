import {
  GET_ALERT_DATA,
  GET_ALERT_DATA_START,
  GET_ALERT_DATA_FAIL
} from "./../actions/actionTypes";

const DEFAULT_STATE = {
  isDataLoaded: false,
  isLoading: false,
  alertData: {},
  error:{}
};

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GET_ALERT_DATA_START:
      return { ...state, isLoading: true };
    case GET_ALERT_DATA:
      return {
        ...state,
        isLoading: false,
        isDataLoaded: true,
        alertData: action.alertData.data
      };
    case GET_ALERT_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        isDataLoaded: false,
        error: action.error
      }
    default:
      return state;
  }
};
