import React, { Component } from 'react';
import Toolbar from './Toolbar/Toolbar';
import SideDrawer from './SideDrawer/SideDrawer';
import Backdrop from './Backdrop/Backdrop';
import './Navbar.css';
import './Navbar-responsive.css'


class NavbarComponent extends Component {

    state = {
        sideDrawerOpen: false
      };
    
      drawerToggleClickHandler = () => {
        this.setState((prevState) => {
          return {sideDrawerOpen: !prevState.sideDrawerOpen};
        });
      };
    
      backdropClickHandler = () => {
        this.setState({sideDrawerOpen: false});
      };
      activeStatus = {
        backgroundColor: "#BE1E2D",
        borderRadius: 5,
        // paddingRight: 5,
        // paddingLeft: 10,
        // marginLeft: 5,
        // marginRight: 5,
        padding:"12px 20px",
        color: "white",
        boxShadow: "0px 3px 10px 0px rgba(250,165,25,0.68)"
       
      }
      render() {
        return (
          <div style={{height: '100%'}}>
            <Toolbar drawerClickHandler={this.drawerToggleClickHandler} {...this.props} />
            <SideDrawer click={this.backdropClickHandler} show={this.state.sideDrawerOpen} {...this.props} />
            {this.state.sideDrawerOpen?<Backdrop click={this.backdropClickHandler} {...this.props} />:''}
          </div>
        );
      }
}

export default NavbarComponent ;