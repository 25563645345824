// import lib
import Loadable from 'react-loadable'

// import components
import Loading from './../loaders/Loading'

const routes = [
    {
        path: '/',
        exact: true,
        status:'HOME',
        component: Loadable({
          loader: () => import('./../../WeekCard/WeekCard'),
          loading: Loading,
        }),
      }, 
      {
        path: '/&state=*',
        exact: true,
        status:'HOME',
        component: Loadable({
          loader: () => import('./../../WeekCard/WeekCard'),
          loading: Loading,
        }),
      }, 
  {
    path: '/submitted',
    exact: true,
    status:'SUBMITTED',
    component: Loadable({
      loader: () => import('./../../WeekCard/WeekCard'),
      loading: Loading,
    }),
  },
  {
    path: '/open',
    exact: true,
    status:'OPEN',
    component: Loadable({
      loader: () => import('./../../WeekCard/WeekCard'),
      loading: Loading,
    }),
  },
  {
    path: '/approved',
    exact: true,
    status:'APPROVED',
    component: Loadable({
      loader: () => import('./../../WeekCard/WeekCard'),
      loading: Loading,
    }),
  },
  {
    path: '/rejected',
    exact: true,
    status:'REJECTED',
    component: Loadable({
      loader: () => import('./../../WeekCard/WeekCard'),
      loading: Loading,
    }),
  },
  {
    path: '/correction',
    exact: true,
    status:'CORRECTION',
    component: Loadable({
      loader: () => import('./../../WeekCard/WeekCard'),
      loading: Loading,
    }),
  },
  {
    path: '/calendar',
    exact: true,
    component: Loadable({
      loader: () => import('./../../DayCard/DayCard'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/open',
    exact: true,
    component: Loadable({
      loader: () => import('./../../TimecardComponent/Timecard'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/open&state=*',
    exact: true,
    component: Loadable({
      loader: () => import('./../../TimecardComponent/Timecard'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/locked',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/locked&state=*',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/correction',
    exact: true,
    component: Loadable({
      loader: () => import('./../../TimecardComponent/Timecard'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/correction&state=*',
    exact: true,
    component: Loadable({
      loader: () => import('./../../TimecardComponent/Timecard'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/submitted',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/submitted&state=*',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/approval',
    exact: true,
    component: Loadable({
      loader: () => import('./../../Approval/ApprovalComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/approval&state=*',
    exact: true,
    component: Loadable({
      loader: () => import('./../../Approval/ApprovalComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/approved',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/approved&state=*',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/rejected',
    exact: true,
    component: Loadable({
      loader: () => import('./../../TimecardComponent/Timecard'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/rejected&state=*',
    exact: true,
    component: Loadable({
      loader: () => import('./../../TimecardComponent/Timecard'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/inpayroll',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/inpayroll&state=*',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/checkprocessing',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/checkprocessing&state=*',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/paid',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '/timecard/:id/paid&state=*',
    exact: true,
    component: Loadable({
      loader: () => import('./../../SubmittedComponent/SubmitComponent'),
      loading: Loading,
    }),
  },
  {
    path: '*',
    exact: true,
    status:'HOME',
    component: Loadable({
      loader: () => import('./../../WeekCard/WeekCard'),
      loading: Loading,
    }),
  },
  
]

export default routes