import {
    GET_ADMIN_COMMENT,
    GET_ADMIN_COMMENT_START,
    GET_ADMIN_COMMENT_FAIL
  } from "./../actions/actionTypes";
  
  const DEFAULT_STATE = {
    isDataLoaded: false,
    isLoading: false,
    comment: {},
    error:{}
  };
  
  export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case GET_ADMIN_COMMENT_START:
        return { ...state, isLoading: true };
      case GET_ADMIN_COMMENT:
        return {
          ...state,
          isLoading: false,
          isDataLoaded: true,
          comment: action.comment.data.TimeCardComments
        };
      case GET_ADMIN_COMMENT_FAIL:
        return {
          ...state,
          isLoading: false,
          isDataLoaded: false,
          error: action.error
        }
      default:
        return state;
    }
  };
  