import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import * as actions from "./../../../../actions";
import './SideDrawer.css';
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader, Row, Col } from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Calendar from 'react-calendar';
import moment from 'moment';
import 'antd/dist/antd.css';

class sideDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      savemodal: false,
      helpModal: false,
      date: new Date(),
      alertDate: moment(new Date()).format('DD-MM-YYYY')
    };
    this.handleSelectDate = this.handleSelectDate.bind(this);
    this.toggle = this.toggle.bind(this);
    this.helpToggle = this.helpToggle.bind(this);
    this.redirectToHome = this.redirectToHome.bind(this);
  }
  handleClick = () => {
    this.props.keycloak.logout();
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  helpToggle() {
    console.log(navigator.userAgent);
    this.setState({ helpModal: !this.state.helpModal });

    this.props.click();
  }

  savetoggle = (event) => {
    this.setState({
      savemodal: !this.state.savemodal
    });



    if (event.target.innerHTML === "Leave") {

      if (localStorage.getItem('location_id') === 'calendar') {
        this.setState({
          modal: true
        })
        event.preventDefault();
      } else if (localStorage.getItem('location_id') === 'home') {
        this.props.history.push(`/`);
      } else {
        var nav_loc = localStorage.getItem('loc_array');
        this.props.history.push(`/${nav_loc}`);
      }

      localStorage.setItem('localVar', 'false');
      localStorage.setItem('location', '');
      localStorage.setItem('location_id', '');
    } else {
      this.setState({
        modal: false
      })
    }

  };


  handleSelectDate = (e) => {
    console.log('calendar', e)
    e.preventDefault()
    this.setState({
      modal: !this.state.modal
    })

    this.props.fetchAssignmentsForDay(this.props.employeeID, this.state.alertDate);
    this.props.history.push("/calendar")
  }

  redirectToHome = () => {
    window.location.replace(window.location.origin);
  }


  onChange = (date) => {
    this.setState({ date, alertDate: moment(date).format('DD-MM-YYYY') });

    console.log('date', moment(date).format('DD-MM-YYYY'));

  }
  render() {
    let drawerClasses = 'side-drawer';
    if (this.props.show) {
      drawerClasses = 'side-drawer open';
    }

    return (

      <nav className={drawerClasses}>
        <ul className="navbar-nav margin-top-xs-neg-20">
          <li className="nav-item active" id="home">
            <div onClick={this.props.click} id="home"> <NavLink className="nav-link" exact to="/" id="home">Home</NavLink></div>
          </li>
          <li className="nav-item">
            <div onClick={this.props.click}><NavLink className="nav-link" to="/open">Open</NavLink></div>
          </li>
          <li className="nav-item">
            <div onClick={this.props.click}><NavLink className="nav-link notif" to="/correction">Correction</NavLink></div>
          </li>
          <li className="nav-item">
            <div onClick={this.props.click}><NavLink className="nav-link notif" to="/approved">Approved</NavLink></div>
          </li>
          <li className="nav-item">
            <div onClick={this.props.click}><NavLink className="nav-link" to="/submitted">Submitted</NavLink></div>

          </li>

          <li onClick={this.toggle} className="nav-item" id="calendar">
            <div onClick={this.props.click} id="calendar"><NavLink className="nav-link" to="#" id="calendar">Calendar</NavLink></div>
          </li>

          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} id="calendar_modal">
            <div className="cal_wrapper">
              <div className="ant-alert ant-alert-info ant-alert-no-icon">
                <span className="ant-alert-message">{`You selected date: ${this.state.alertDate}`}</span><span className="ant-alert-description"></span>
              </div>
              <Calendar onChange={this.onChange} value={this.state.date} />
              <Row className="justify-content-center">
                <Col md="3" sm="5" xs="5">
                  <button onClick={this.handleSelectDate} className="fetch_btn">Fetch Assignments</button>
                </Col>
              </Row>
            </div>
          </Modal>

          <li className="bar1">
          </li>

          <li className="nav-item" onClick={this.helpToggle}>
              <Link className="nav-link" to="">Help</Link>
          </li>
          <Modal isOpen={this.state.helpModal} toggle={this.helpToggle} className={this.props.className} id={"calendar_modal"}>
              <ModalHeader toggle={this.helpToggle}>Help</ModalHeader>
              <ModalBody>
                  <iframe title="how to use" src="https://www.youtube.com/embed/QHXESFubBaQ" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
                  <div style={{marginTop: '1em'}}>
                      <a href={'./TimeCapsureInstructions.pdf'} target={'_blank'}>Open PDF Instructions</a>
                  </div>
              </ModalBody>
          </Modal>

          <li className="nav-item">
            <a className="nav-link" target="_blank" href="https://employees.tradesmeninternational.com"><span style={{color: '#BE1E2D'}}>Employee Connect</span></a>
          </li>

 

          <li className="nav-item">
            <Link className="nav-link" to="#" onClick={this.handleClick}>Log Out</Link>
          </li>
        </ul>
      </nav>
    );
  }
};
function mapStateToProps(state) {
  return {
    keycloak: state.authInfo.keycloak,
    employeeID: state.authInfo.user.employeeID,
    alerts: state.alerts
  };
}

export default connect(mapStateToProps, actions)(sideDrawer);