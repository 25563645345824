import {
   LOADING,SUBMIT_LOADING,
   SIGNATURE_SUBMIT_LOADING
  } from "./../actions/actionTypes";

  const DEFAULT_STATE = {
   isLoading:false,
   isSubmitLoading:false,
   isSignatueSubmitLoading:false
  };

  export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
      case LOADING:
        return { ...state, isLoading: action.loading };
      case SUBMIT_LOADING:
        return { ...state, isSubmitLoading: action.submit_loading };  
        
        case SIGNATURE_SUBMIT_LOADING:
          return { ...state, isSignatueSubmitLoading: action.signature_submit_loading }; 
        default:
        return state;
    }
  };
  