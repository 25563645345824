import {combineReducers} from 'redux';
import currentAssignments from "./currentAssignments";
import error from "./error";
import authInfo from "./authInfo";
import timecard from "./timecard";
import savedObject from "./savedObject";
import imageObject from './imageObject';
import fetchImageObject from './fetchImageObject';
import { reducer as formReducer } from 'redux-form';
import alerts from "./alerts";
import adminComment from "./adminComment";
import pastAssignments from "./pastAssignments";
import assignmentsForDay from "./assignmentsForDay";
import ipaddress from "./ipaddress";
import loading from './loading';

const rootReducer = combineReducers({currentAssignments,error,authInfo,form:formReducer,timecard,savedObject,imageObject,fetchImageObject,alerts,adminComment,pastAssignments,assignmentsForDay,ipaddress,loading});

export default rootReducer;
